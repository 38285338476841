import React from 'react';
import PropTypes from 'prop-types';

import Sidebar from './Sidebar';

export function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen antialiased leading-relaxed text-gray-700 font-display">
      <Sidebar />
      <main className="flex flex-col flex-1 w-full mx-auto bg-black">
        {children}
      </main>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};
