import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import SiteContext from '../context/site-context';
import { useGraphQL } from '../hooks/use-graphql';
import Logo from './logo-landscape';

const Navbar = () => {
  const { isSidebarOpen, setSidebarOpen } = React.useContext(SiteContext);
  const { site } = useGraphQL();
  return (
    <header className="relative flex flex-wrap items-center justify-between max-w-6xl p-8 mx-auto font-bold font-display">
      <Link to="/" className="flex-1 sm:h-6">
        <Logo
          className="h-full fill-current"
          aria-label={site.siteMetadata.title}
        />
      </Link>
      <div className="flex items-center">
        <a
          href={`tel:${site.siteMetadata.phone}`}
          className="hidden uppercase sm:block"
        >
          Call
          <span className="font-thin"> {site.siteMetadata.phoneFormatted}</span>
        </a>
        <button
          onClick={() => {
            setSidebarOpen(!isSidebarOpen);
          }}
          type="button"
          className={`hamburger hamburger--collapse relative z-40 focus:outline-none flex pr-0 ${
            isSidebarOpen ? ' is-active' : ''
          }`}
          aria-controls="menu"
          aria-expanded="false"
          aria-label="Menu"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
    </header>
  );
};

Navbar.propTypes = {
  white: PropTypes.bool,
};

export { Navbar };
