import React from 'react';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';

import { useForm } from '../hooks';
import { Form, Input, TextArea } from './FormElements';

export function ContactForm({ handleCloseModal, hasCloseButton = true }) {
  const { state, handleSubmit, handleChange } = useForm({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  return (
    <Form
      action="/success/"
      name="contact"
      onSubmit={handleSubmit}
      handleChange={handleChange}
      className="relative flex flex-col w-full max-w-lg px-4 pt-12 m-4 bg-white shadow-2xl font-display sm:m-auto"
    >
      <div className="absolute inset-x-0 top-0 flex items-center justify-center mt-0">
        <div className="px-12 py-3 text-white uppercase bg-black rounded transform -translate-y-1/2 font-display sm:text-3xl">
          <h2>Lets Talk</h2>
        </div>
      </div>
      {hasCloseButton && (
        <button
          className="absolute top-0 right-0 z-10 p-2 text-xl text-black bg-white rounded-full transform translate-x-full -translate-y-full bg-opacity-50 focus:outline-none focus:bg-gray-200 focus:bg-opacity-75 focus:shadow-outline"
          onClick={handleCloseModal}
          type="button"
        >
          <MdClose />
        </button>
      )}
      <div className="flex flex-col mt-6 space-y-6">
        {/* Name */}
        <Input
          handleChange={handleChange}
          label="Name"
          name="name"
          value={state.name}
        />

        {/* Email address */}
        <Input
          handleChange={handleChange}
          label="Email"
          name="email"
          value={state.email}
          type="email"
        />

        {/* Phone number */}
        <Input
          handleChange={handleChange}
          label="Phone"
          name="phone"
          value={state.phone}
          type="tel"
        />

        {/* Message */}
        <TextArea
          handleChange={handleChange}
          label="Message"
          name="message"
          value={state.message}
        />

        {/* Submit */}
        <button
          type="submit"
          className="px-12 py-4 ml-auto -mt-8 leading-none text-white uppercase bg-black rounded transform translate-y-1/2 hover:bg-gray-900 focus:outline-none focus:shadow-outline font-display"
        >
          Send
        </button>
      </div>
    </Form>
  );
}

ContactForm.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  hasCloseButton: PropTypes.bool,
};
