import React, { useState } from 'react';
import { SocialIcon } from 'react-social-icons';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import SiteContext from '../context/site-context';

import { useGraphQL } from '../hooks';
import { Carousel } from './Carousel';
import { Modal } from './Modal';
import logoType from '../images/logotype.svg';

export function Hero() {
  const { isSidebarOpen, setSidebarOpen } = React.useContext(SiteContext);

  const {
    site: {
      siteMetadata: { title },
    },
  } = useGraphQL();

  const { logo } = useGraphQL();

  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  return (
    <div className="relative h-screen text-white bg-black">
      <Carousel />
      <div className="absolute inset-0 flex flex-col w-full pointer-events-none">
        <header className="bg-gradient">
          <div className="flex flex-wrap items-center justify-center w-full px-12 pt-4 pb-24 mx-auto pointer-events-auto max-w-screen-xl md:justify-between">
            <img
              className="h-8 md:h-6"
              src={logoType}
              alt="4x4 Together logo."
            />
            <div className="flex items-center justify-center flex-shrink-0 w-full mt-4 md:w-auto">
              <SocialIcon
                style={{ height: `1.5rem`, width: `1.5rem` }}
                url="https://www.facebook.com/4x4together"
                target="_blank"
                rel="noopener noreferrer"
                bgColor="#fff"
              />
              <SocialIcon
                className="ml-2"
                style={{ height: `1.5rem`, width: `1.5rem` }}
                url="https://www.instagram.com/4x4together"
                target="_blank"
                rel="noopener noreferrer"
                bgColor="#fff"
              />
              <SocialIcon
                className="ml-2"
                style={{ height: `1.5rem`, width: `1.5rem` }}
                url="https://twitter.com/4x4together"
                target="_blank"
                rel="noopener noreferrer"
                bgColor="#fff"
              />
              <SocialIcon
                className="ml-2"
                style={{ height: `1.5rem`, width: `1.5rem` }}
                url="https://www.youtube.com/channel/UCsR03-p26KNsjNDFOeszueg"
                target="_blank"
                rel="noopener noreferrer"
                bgColor="#fff"
              />
              <span className="hidden ml-8 text-sm md:block">Get in touch</span>
              <button
                onClick={() => {
                  setSidebarOpen(!isSidebarOpen);
                }}
                type="button"
                className={`hamburger hamburger--collapse relative z-40 focus:outline-none flex pr-0 ${
                  isSidebarOpen ? ' is-active' : ''
                }`}
                aria-controls="menu"
                aria-expanded="false"
                aria-label="Menu"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
          </div>
        </header>
        <div className="flex flex-col items-center justify-center mt-auto font-bold">
          <div className="inset-x-0 flex items-center justify-center font-display">
            <button
              className="flex items-center px-1 py-3 bg-black rounded shadow-md pointer-events-auto"
              onClick={handleOpenModal}
              type="button"
            >
              <Image
                className="ml-4"
                fixed={logo.childImageSharp.fixed}
                alt="4x4 Together logo."
              />
              <div className="mx-4 whitespace-no-wrap sm:text-3xl">
                Contact Us Today
              </div>
            </button>
          </div>
          <Modal
            isModalOpen={isModalOpen}
            handleCloseModal={handleCloseModal}
          />
          <footer className="w-full mt-12 bg-black pointer-events-auto">
            <div className="flex-wrap items-center justify-between w-full px-12 py-2 mx-auto font-sans text-xs font-thin text-center text-white uppercase max-w-screen-xl sm:flex">
              <Link to="/blog">Check out our new blog!</Link>
              <div>
                <span>
                  © {new Date().getFullYear()} {title}
                </span>
                <span className="mx-1">|</span>
                <span className="">
                  Website by
                  <a
                    className="inline-flex px-1 font-semibold"
                    href="https://phirannodesigns.com.au"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Phiranno Designs
                  </a>
                </span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}
