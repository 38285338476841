import PropTypes from 'prop-types';
import React from 'react';

export function renderDotControls({ currentSlide, goToSlide, slideCount }) {
  return (
    <div className="absolute inset-y-0 right-0 flex items-center">
      <div className="w-full px-12 mx-auto max-w-screen-xl">
        <ul className="relative flex flex-col space-y-4">
          {[...Array(slideCount)].map((_, i) => (
            <li key={i} className="inline-flex">
              <button
                type="button"
                aria-label="Set slide"
                onClick={() => goToSlide(i)}
                className={`${
                  currentSlide === i ? 'opacity-100' : 'opacity-50'
                } rounded-full h-2 w-2 bg-white shadow`}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
renderDotControls.propTypes = {
  currentSlide: PropTypes.number,
  goToSlide: PropTypes.func,
  slideCount: PropTypes.number,
};
