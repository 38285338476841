import React, { useEffect } from 'react';

import { Link } from 'gatsby';
import SiteContext from '../context/site-context';

export default function Sidebar() {
  const { isSidebarOpen, setSidebarOpen } = React.useContext(SiteContext);
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.keyCode === 27) {
        setSidebarOpen(false);
      }
    };

    if (typeof document !== 'undefined') {
      document.addEventListener('keydown', handleEscape);
    }
  }, [setSidebarOpen]);

  return (
    <>
      <button
        onClick={() => setSidebarOpen(false)}
        type="button"
        aria-label="Close menu."
        className={`fixed inset-0 z-30 w-full h-full bg-transparent-black-50 transition duration-200 ease-in-out ${
          isSidebarOpen
            ? 'pointer-events-auto opacity-100'
            : 'pointer-events-none opacity-0'
        }`}
      />
      <div
        style={{ opacity: '0.9' }}
        className={`fixed inset-y-0 right-0 z-50 flex text-3xl font-thin text-white bg-gray-700 px-8 transform transition duration-200 ease-in-out font-display uppercase ${
          isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        id="menu"
      >
        <div className="relative flex flex-col items-end">
          <div className="mt-8">
            <button
              onClick={() => {
                setSidebarOpen(!isSidebarOpen);
              }}
              type="button"
              className={`hamburger hamburger--collapse z-50 -mr-2 focus:outline-none flex pr-0 ${
                isSidebarOpen ? ' is-active' : ''
              }`}
              aria-controls="menu"
              aria-expanded={isSidebarOpen}
              aria-label="Close menu."
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
          <div className="relative flex items-center justify-center w-full my-auto">
            <nav className="-mt-8">
              <ul className="leading-none text-right">
                <li className="mt-6 first:mt-0">
                  <Link to="/" onClick={() => setSidebarOpen(false)}>
                    <span className="font-bold">Home</span> Page
                  </Link>
                </li>
                <li className="mt-6 first:mt-0">
                  <Link to="/blog/" onClick={() => setSidebarOpen(false)}>
                    <span className="font-bold">Our Blog</span> Page
                  </Link>
                </li>
                <li className="mt-6 first:mt-0">
                  <Link to="/contact/" onClick={() => setSidebarOpen(false)}>
                    <span className="font-bold">Contact</span> Us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
